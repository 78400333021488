@import '../../platformui/Inputs/custom-select.scss';

@include CustomSelect(
  $toggle-bg: c('sky-background'),
  $toggle-border: c('sky-base'),
  $toggle-color: c('stone-dark'),
  $hover-bg: c('sky-background'),
  $dropdown-border: c('sky-background'),
  $dropdown-color: c('stone-dark'),
  $chevron-color: c('sky-base'),
  $margin: 0 0 rem-calc(32) 0
) {
  .c-custom-select {
    &__item {
      &--selected {
        font-weight: bold;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}
