@import '../Framework/platform-mixins/Chevron.scss';
@mixin AlertList($spaceBetween: 25px) {
  .c-alertlist {
    .c-alert__inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    @media print {
      display: none;
    }
    .c-alert {
      margin-bottom: 0;

      &:not(:first-of-type) {
        margin-top: $spaceBetween;
      }
    }
  }
  @content;
}
