@import '../../platformui/ScrollToTop/styles';

@include ScrollToTop(
  $bgImage: url('../../framework/images/arrow-up.svg'),
  $height: 50px,
  $width: 50px,
  $focusColor: c('black')
) {
  @include breakpoint(large) {
    bottom: 10px;
  }

  &--vardpersonal {
    background-image: url('../../framework/images/arrow-up-blue.svg');
  }

  &--footer-is-visible {
    bottom: 10px;
  }

  body.no-scroll & {
    display: none;
  }
}
