@import '../../platformui/SearchEntryFormBlock/styles.scss';

@include SearchEntryForm() {
  .entryform-searchform {
    /* bottom space should be 20px but searchform has 10px bottom margin */
    padding: 0;

    .c-search {
      &__field {
        margin-top: 0;
        margin-bottom: 0;

        padding-right: rem-calc(69);

        &__query {
          background-color: white;
          margin-left: rem-calc(10);
          width: calc(100% - 100px);

          @include breakpoint(medium) {
            width: calc(100% - 140px);
          }

          @include breakpoint(large) {
            width: calc(100% - 190px);
            padding-left: rem-calc(56);
            margin-left: rem-calc(30);
          }
        }

        &__button {
          margin-right: rem-calc(10);

          @include breakpoint(large) {
            width: 110px;
            margin-right: 30px;
          }
        }

        .icon-search {
          left: 40px;
        }

        @include breakpoint(medium) {
          padding-right: rem-calc(87);
        }
      }
    }
  }
}
