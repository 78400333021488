@mixin CollapsibleFacets(
  $buttonColor: white,
  $borderColor: $black,
  $borderOpacity: 0.2
) {
  .c-collapsible-facets {
    width: 100%;

    &__button {
      background-color: transparent;
      color: $buttonColor;
      cursor: pointer;
      display: block;
      font-size: rem-calc(16);
      margin-bottom: 0;
      padding-left: 20px;
      position: relative;
      text-align: left;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .c-search__tools & {
        border-top: solid 1px rgba($borderColor, $borderOpacity);
        height: 50px;
        width: 100%;

        @include breakpoint(medium) {
          border-left: solid 1px rgba($borderColor, $borderOpacity);
          border-top: none;
          position: absolute;
          right: 0;
          top: 0;
          width: auto;
        }
      }

      &:focus {
        outline: none;
      }

      &__icon {
        @include icon('f107');
        display: inline-block;
        position: absolute;
        top: 1px;
        transition: all 0.3s ease;
        transform: rotate(0deg);
        transform-origin: center center;
        right: 10px;
        top: 6px;
        font-size: 32px;

        @include breakpoint(medium) {
          font-size: 20px;
          position: relative;
          right: -10px;
          top: 1px;
        }

        &:before {
          margin: 0;
        }

        .c-collapsible-facets--is-expanded & {
          display: inline-block;
          transform: rotate(180deg);
        }
      }
    }

    &__panel {
      height: 0;
      overflow: hidden;
      visibility: hidden;
      width: 100%;

      .js-off & {
        display: block;
        height: auto;
        overflow: visible;
        visibility: hidden;
      }

      &--is-expanded {
        border-top: solid 1px rgba($borderColor, $borderOpacity);
        height: auto;
        overflow: visible;
        padding-top: 20px;
        visibility: visible;

        @include breakpoint(medium) {
          padding-top: 30px;
        }
      }
    }

    .c-facets {
      border-bottom: none;
      margin-bottom: 0;
      transition: all 0.2s ease;
      transform: translateY(20px) scale(0.9, 0.9);
      opacity: 0;
    }

    &--is-expanded {
      .c-facets {
        transform: translateX(0) scale(1, 1);
        opacity: 1;
      }
    }
    @content;
  }
}
