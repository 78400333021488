@import '../Framework/platform-mixins/Chevron.scss';
@mixin StatusMessage(
  $icon: '',
  $useMediumBreakpoint: true,
  $bgColor: #fff8e0,
  $titleSize: rem-calc(16),
  $paddingSM: 16px 20px,
  $paddingLG: 22px 24px,
  $iconSizeSM: 20px,
  $iconSizeLG: 30px,
  $minHeightSM: 60px,
  $minHeightLG: 80px,
  $iconMarginSM: 2px 0 0 0,
  $iconMarginLG: 0,
  $headingIndent: 30px
) {
  .statusmessage {
    position: relative;
    background-color: $bgColor;
    min-height: $minHeightSM;
    padding: $paddingSM;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.15) inset;

    @include breakpoint(large) {
      border-top: none;
      min-height: $minHeightLG;
      padding: $paddingLG;
    }

    &:first-of-type {
      border-top: none;
      margin-bottom: 0;
    }

    &:last-of-type {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15),
        0 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
      margin-bottom: 25px;
    }
    &__author {
      font-style: italic;
    }

    &__heading {
      display: grid;
      grid-template-columns: ($iconSizeSM + 10) 1fr rem-calc(30);
      align-items: flex-start;
      column-gap: rem-calc(0.4);
      margin-left: auto;
      margin-right: auto;
      max-width: 80rem; // same as .row from foundation
      width: 100%;

      h2 {
        text-indent: $headingIndent;
        font-size: $titleSize;
        line-height: 1.6;
        font-weight: bold;
        margin: 0px;
        padding-right: 20px;
        grid-column-start: 1;
        grid-column-end: 3;
      }
      &__text {
        margin-right: 10px;
      }

      &__region_label {
        white-space: nowrap;
        padding: 2px 10px;
        font-size: rem-calc(14);
        background-color: white;
        border: 1px solid #ffe699;
        border-radius: 5px;
      }
      @include breakpoint(large) {
        &__region_label {
          padding: 5px 10px;
        }
        grid-template-columns: ($iconSizeLG + 10) 1fr rem-calc(220);
        column-gap: rem-calc(0.4);
        h2 {
          font-size: rem-calc(18);
          text-indent: 0;
          width: 100%;
          grid-column-start: 2;
          grid-column-end: 3;
        }
        &__text {
          margin-right: 20px;
        }
      }
    }

    &__content {
      display: none;
      position: relative;
      max-width: 80rem;
      width: 100%;
      font-size: rem-calc(16);
      grid-column-end: 1;
      grid-column-start: 3;
      padding-top: 5px;
      padding-bottom: 10px;
      @include breakpoint(large) {
        grid-column-end: 2;
        grid-column-start: 2;
      }
      &--visible {
        display: block;
      }
      p {
        margin-bottom: 0;
        padding: 0;
      }
      p:first-child {
        margin-top: 0;
      }
      @include breakpoint(large) {
        font-size: rem-calc(18);
        p {
          font-size: rem-calc(18);
        }
      }
    }

    &__icon-container {
      position: absolute;

      @include breakpoint(large) {
        position: relative;
      }
      display: flex;
      align-items: center;
      margin: $iconMarginSM;

      @if $useMediumBreakpoint == true {
        @include breakpoint(large) {
          margin: $iconMarginLG;
          display: block;
          align-self: flex-start;
        }
      }
    }

    &__icon {
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('#{$icon}');
      background-size: $iconSizeSM;
      height: $iconSizeSM;
      margin: 0;
      width: $iconSizeSM;

      @include breakpoint(large) {
        margin: 0;
        background-size: $iconSizeLG;
        height: $iconSizeLG;
        width: $iconSizeLG;
      }
    }
    &__expand-button {
      background-color: transparent;
      white-space: nowrap;
      font-size: rem-calc(14);
      letter-spacing: 1.4px;
      position: static;
      display: flex;
      justify-content: flex-end;

      &__text {
        text-transform: uppercase;
        display: none;
        @include breakpoint(large) {
          display: unset;
        }
      }

      &:before {
        content: '';
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: auto;
        height: $minHeightSM;
        cursor: pointer;
        @include breakpoint(large) {
          height: $minHeightLG;
        }
      }

      @include Chevron(
        $className: statusmessage__expand-button__icon,
        $chevronColor: #353535,
        $height: 24px,
        $width: 30px,
        $top: unset,
        $rightLinePosition: calc(100% - 14px),
        $leftLinePosition: calc(100% - 20px),
        $lineLength: 9px
      ) {
        background-color: transparent;
        position: relative;
        display: flex;
        align-items: center;

        @include breakpoint(large) {
          position: relative;
          right: unset;
          top: 3px;
          &:before,
          &:after {
            top: 3px;
          }
        }
      }
      @include focus-handler($offset: 2px);
    }
  }

  @content;
}
