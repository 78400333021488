@import '../../platformui/SearchBlock/styles';
@import '../../platformui/Pagination/styles';
@import '../../platformui/Spinner/styles';
@import './list/styles';
@import '../buttons/button.scss';

.limited-searchblock {
  .u-search-page {
    margin-top: 0;

    .t-total-hits {
      font-weight: normal;
    }
  }
}

@include spinner($bgColor: c('sky-base'), $margin: 0 auto) {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

@include SearchList($marginBottom: 0px) {
  .c-related {
    padding: 0;

    &__empty-container {
      h3 {
        margin-bottom: 0;
      }
    }

    li {
      border-bottom: 1px solid c('stone-line');
    }

    &__footer {
      display: flex;
      flex-direction: column;
    }
  }
}

@include SearchLegend(
  $fontFamily: $secondary-font,
  $fontWeight: 500,
  $color: c('black'),
  $fontSize: rem-calc(14),
  $letterSpacing: 1.4px,
  $textTransform: uppercase
);

.c-search__legend {
  margin-left: 10px;
  margin-bottom: 10px;
}

@include SearchField(
  $searchFieldLabel: c('grey-500'),
  $buttonBg: c('sky-base'),
  $buttonColor: c('white'),
  $buttonBorderRadius: (
    10px 10px 10px 10px,
    10px,
  ),
  $buttonWidthLarge: 110px,
  $buttonHoverBg: c('sky-base'),
  $searchFieldMarginBottom: (
    rem-calc(22),
    rem-calc(22),
  )
) {
  .c-search__field {
    height: 70px;
    border-radius: 10px;
    z-index: 1;
    padding-inline: 0;

    @include breakpoint(large) {
      height: 120px;
    }

    &__button {
      position: absolute;
      top: 15%;
      height: 50px;
      font-size: rem-calc(18);
      font-weight: bold;
      text-transform: uppercase;

      @include focus-handler();

      @include breakpoint(large) {
        height: 60px;
        top: 25%;
        font-size: rem-calc(24);
      }
    }

    .icon-search {
      position: absolute;
      top: 30%;
      z-index: 1;

      &:before {
        color: c('sky-dark');

        @include breakpoint(small only) {
          content: none;
        }

        @include breakpoint(medium only) {
          content: none;
        }
      }
    }
  }

  @include SearchFieldQuery(
    $searchFieldBg: c('white'),
    $border: solid 1px c('sky-base'),
    $borderRadius: (
      10px 10px 10px 10px,
      10px,
    ),
    $searchFieldFocusBorder: 1px solid c('sky-base'),
    $searchFieldFocusShadow: inset 0 5px 10px 0 rgba(c('black'), 0.2),
    $paddingLeft: (
      20px,
      56px,
    )
  ) {
    .c-search__field__query {
      position: absolute;
      top: 15%;
      height: 50px;
      border-right: solid 1px c('sky-base');
      font-size: rem-calc(18);
      color: c('stone-dark');
      background-color: c('sky-background');

      @include base-font-family();
      @include focus-handler();

      width: calc(100% - 78px);

      @include breakpoint(medium) {
        width: calc(100% - 140px);
        padding-left: rem-calc(20);
      }

      @include breakpoint(large) {
        top: 25%;
        height: 60px;
        padding-left: rem-calc(56);
      }

      &::placeholder {
        color: c('grey-500');
        font-style: italic;
        text-overflow: ellipsis;
      }
    }
  }

  @include QuickSearch(
    $panelBg: c('white'),
    $borderColor: c('stone-line'),
    $labelFontColor: c('stone-dark'),
    $linkColor: c('sky-base')
  ) {
    box-shadow: 0 2px 4px 0 c('stone-line');
    height: 80vh;
    padding: 16px 20px;
    @include secondary-font-family();

    @include breakpoint(small) {
      overflow-y: auto;
    }

    @include breakpoint(large) {
      height: unset;
      border-radius: 10px;
      width: calc(100% - 217px);
    }

    @include focus-handler($color: c('grey-500'));

    &__search-hits {
      @include breakpoint(large) {
        overflow-y: auto;
        max-height: 50vh;
      }
    }

    &__search-hits__information-type {
      height: 16px;
      padding-left: 6px;
      line-height: 16px;
      margin-block: 4px;
      color: c('stone-dark');
      font-style: normal;
      font-size: 1rem;

      &--1001 {
        border-left: 8px solid c('main');
      }

      &--1002 {
        border-left: 10px solid c('sky-clear');
      }

      &--1003 {
        border-left: 10px solid c('grass-base');
      }

      &--1004 {
        border-left: 10px solid c('sun-clear');
      }

      @include breakpoint(large) {
        margin-left: 30px;
      }
    }

    &__search-hits__selection-code {
      display: flex;
      gap: 16px;
      font-size: 1rem;
      color: c('stone-dark');

      @include breakpoint(large) {
        margin-left: 30px;
      }

      & > a {
        text-decoration: underline !important;
      }
    }

    &__search-hits__item {
      &[href$='.pdf'] {
        .c-search__quicksearch__search-hits__heading {
          @include icon('e82b', $pseudo: after);
        }
      }
    }

    @include button(
      $bg: c('sky-base'),
      $border: none,
      $border-radius: 25px,
      $color: c('white'),
      $className: 'c-search__quicksearch__search-hits__show-results',
      $hoverBg: c('sky-base'),
      $hoverColor: c('white')
    ) {
      margin-top: 30px;
      text-align: center;
      font-weight: bold;
      font-size: 1rem;
      display: flex;
      justify-content: center;

      @include breakpoint(large) {
        width: 250px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.searchblock {
  .c-search__quicksearch {
    a {
      text-decoration: none;
    }

    position: absolute;

    @include breakpoint(large) {
      top: 90px;
      width: calc(100% - 190px);
      left: 30px;
    }
  }
}

/** This is the "Ladda 10 till" button under the search list. */
@include pagination($fontSize: 20px) {
  border: solid 1px c('sky-base');
  border-radius: 30px;
  color: c('sky-base');
  margin: 0 10px;
  width: 100%;
  padding: 12px 40px;
  text-transform: uppercase;
  background-color: c('white');

  &:hover,
  &:focus {
    box-shadow: 0 3px 8px 0 rgba(c('black'), 0.3);
    color: c('white');
    outline: none;
    background-color: c('sky-base');
  }

  &__showall {
    &:hover,
    &:focus {
      color: c('sky-base');
      background-color: c('white');
      box-shadow: none;
      outline: none;
    }
  }

  @include breakpoint(large) {
    margin: 0 auto;
    padding: 12px 50px;
    width: unset;
  }
}

@include SearchTools($borderColor: c('stone-clear'), $borderOpacity: 1) {
  .c-search__tools {
    margin-top: 0px;
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint(medium) {
      flex-direction: column;
    }

    .c-searchlist {
      &__heading {
        @include base-font-family();
        text-align: left;
        font-size: 16px;
        margin: rem-calc(10) rem-calc(20);
      }

      &__footer {
        button {
          cursor: pointer;
        }
      }
    }

    &--has-facets {
      border-top: 1px solid c('stone-clear');
      margin-bottom: rem-calc(20);
      .c-searchlist {
        &__heading {
          margin-top: rem-calc(50);
          @include breakpoint(medium) {
            margin-block: 0;
            border-right: 1px solid c('stone-clear');
            padding-block: rem-calc(10);
            max-width: calc(100% - 220px);
          }
        }
      }
    }
  }
}
