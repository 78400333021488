$globaltheme:nkk;
@import './base/color-palette';
@import './base/async-fonts';
@import './base/font-base';
@import '../icons/css/fontello';

$site-base-font: $base-font;

$editor: false;

@import './base/foundation-settings.scss';
@import 'foundation-sites/scss/foundation.scss';
@include foundation-grid;

@import './base/rtl.scss';
@import './base/font-book.scss';

@import './base/tables.scss';
@import './base/utils.scss';
@import '../../platformui/Framework/print';

/** PAGES **/
@import '../../pages/start/styles.scss';

/** COMPONENTS **/
@import '../../platformui/Framework/platform-mixins/CookieProPanel.scss';
@include CookieProPanel(
  $focusColor: c('grey-200'),
  $acceptBtnTextHoverColor: c('white'),
  $acceptBtnHoverColor: c('sky-dark'),
  $settingsBtnTextHoverColor: c('sky-base'),
  $settingsBtnHoverColor: c('sky-background'),
  $settingsBtnBorder: 1px solid c('sky-base'),
  $fontWeight: bold,
  $btnBorderRadius: 10px,
  $btnSize: 50px
);

@import '../../components/header/regions.scss';
@import '../../components/header-vardpersonal/regions.scss';
@import '../../components/breadcrumb/styles.scss';
@import '../../components/meganav/styles.scss';
@import '../../components/meganav-vardpersonal/styles.scss';
@import '../../components/section/styles.scss';
@import '../../components/image/styles.scss';
@import '../../components/image-gallery/styles.scss';
@import '../../components/linklist/styles.scss';
@import '../../components/chapter/styles.scss';
@import '../../components/chapter-container/styles.scss';
@import '../../components/errorStatic/styles.scss';
@import '../../components/logo/styles.scss';
@import '../../components/footer/styles.scss';
@import '../../components/footer-vardpersonal/styles.scss';
@import '../../components/cookie-panel/styles.scss';
@import '../../components/mobile-nav/styles.scss';
@import '../../components/mobile-nav-vardpersonal/styles.scss';
@import '../../components/collapsible-panel/styles.scss';
@import '../../components/teaser/styles.scss';
@import '../../components/article-footer/styles.scss';
@import '../../components/article-list/styles.scss';
@import '../../components/buttons/styles.scss';
@import '../../components/localnav/styles.scss';
@import '../../components/search/styles.scss';
@import '../../components/article-header/styles.scss';
@import '../../components/globalsearch/styles.scss';
@import '../../components/scrolltop/styles.scss';
@import '../../components/link/styles.scss';
@import '../../components/diagnostic/styles.scss';
@import '../../components/searchentryform/styles.scss';
@import '../../components/subject-intro/styles.scss';
@import '../../components/reference/styles.scss';
@import '../../components/complimentnav/styles.scss';
@import '../../components/infobanner/styles.scss';
@import '../../components/infobanner-vardpersonal/styles.scss';
@import '../../components/subjectgrouplist/styles.scss';
@import '../../components/error404-linklist/styles.scss';
@import '../../components/facets/styles.scss';
@import '../../components/listselect/styles.scss';
@import '../../components/aolist/styles.scss';
@import '../../components/alert/styles.scss';
@import '../../components/alertlist/styles.scss';
@import '../../components/anchor-nav-multilevel/styles.scss';
@import '../../components/region-addition/styles.scss';
@import '../../components/zoom-container/styles.scss';
@import '../../components/activity/styles.scss';
@import '../../components/text/styles.scss';
@import '../../components/process/styles.scss';
@import '../../components/linklist-puff/style.scss';
@import '../../components/information-modal/styles.scss';
@import '../../components/select/styles.scss';
@import '../../components/tab-menu/styles.scss';
@import '../../components/custom-select/styles.scss';

.main,
.c-alertlist,
.c-footer,
.c-reading-aid,
.regions {
  opacity: 1;
}

p:has(+ .reference__block-reference) {
  margin-bottom: 0;
}

ul:has(+ .reference__block-reference) {
  margin-bottom: 0;
}

ol:has(+ .reference__block-reference) {
  margin-bottom: 0;
}

.zoom-container:has(+ .reference__block-reference) > .table-overflow {
  margin-bottom: 0;
}

.large-uncentered:last-child:not(:first-child) {
  @include breakpoint(large) {
    position: static;
    float: right;
    margin-right: 0;
    margin-left: 0;
    clear: none;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
