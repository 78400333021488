@import '../../platformui/Facets/collapsible-facets.scss';
@include CollapsibleFacets(
  $buttonColor: $black,
  $borderColor: c('stone-clear')
) {
  .c-collapsible-facets {
    &__panel {
      &--is-expanded {
        border-top: none;
        padding-block: 0;
        padding-inline: rem-calc(5);
        @include breakpoint(medium) {
          border-top: 1px solid c('stone-clear');
          padding-inline: rem-calc(10);
        }
      }

      .c-facets {
        transform: none;
        opacity: 1;

        &__inner {
          border-color: c('stone-clear');
          padding-block: rem-calc(20);
          @include breakpoint(medium) {
            border: none;
          }
        }
      }
    }

    &__button {
      @include base-font-family();
      @include focus-handler($offset: -8px, $color: $black);
      outline-width: 4px;
      border-top: 1px solid c('stone-clear');
      border-bottom: 1px solid c('stone-clear');
      border-left: none;
      padding: rem-calc(10) rem-calc(20);
      height: rem-calc(42);
      top: -1px;

      &__icon {
        font-size: rem-calc(22);

        @include breakpoint(medium) {
          top: 3px;
        }

        &::before {
          color: c('sky-base');
        }
      }

      @include breakpoint(medium) {
        padding-block: 0;
        padding-bottom: rem-calc(3);
        margin-right: rem-calc(5);
        border-top: none;
        border-bottom: 1px solid transparent;
      }
    }
  }
}
