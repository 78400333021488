@import '../../platformui/TabMenu/styles.scss';

@include TabMenu(
  $fontColor: c('sky-base'),
  $activeFontColor: c('sky-dark'),
  $borderTrack: 4px solid c('sky-line'),
  $activeItemBorderTrack: 4px solid c('sky-dark'),
  $tabBorderColor: c('stone-clear'),
  $tabGap: rem-calc(5)
) {
  .c-tab-menu {
    &__item {
      &:hover {
        border-bottom: 4px solid c('sky-dark');
        margin-bottom: -4px;
        span {
          color: c('sky-dark');
        }
      }
    }
  }
}
