@mixin search-list-item($className: 'c-related',
  $containerPadding: 10px 30px 10px 56px,
  $itemBgColor: false,
  $itemAlternatingBgColor: false,
  $itemBorderRadius: 0) {
  .#{$className} {
    li {
      list-style: none;
      margin-bottom: 0;

      @if $itemBgColor {
        .#{$className}__item {
          background: $itemBgColor;
        }
      }

      @if $itemAlternatingBgColor {
        &:nth-child(odd) {
          .#{$className}__item {
            background: $itemAlternatingBgColor;
          }
        }
      }
    }

    &__items {
      padding-bottom: 10px;
    }

    &__item {
      border-radius: $itemBorderRadius;
      display: block;

      &__container {
        padding: $containerPadding;
        position: relative;
        z-index: 0;
        max-width: 720px;

        &--no-z-index {
          z-index: auto;
        }
      }

      &__heading {
        margin-bottom: 3px;

        em {
          font-weight: 500;
          font-style: inherit;
        }
      }

      &__text {
        margin-bottom: 0;

        em {
          font-weight: bold;
          font-style: inherit;
        }
      }

      &__available-languages {
        margin-block-start: 8px;

        span {
          font-style: italic;
          margin-bottom: 4px;
          line-height: 24px;
          display: block;
        }
      }

      &__languages {
        display: flex;
        align-items: flex-start;
        flex-flow: row wrap;
        padding-left: 0;
        gap: 8px;

        li {
          display: flex;
          align-items: center;
          gap: 8px;

          &:after {
            content: '|';
            cursor: default;
          }

          &:last-of-type {
            &:after {
              content: none;
            }
          }
        }
      }
    }

    @content;
  }
}
