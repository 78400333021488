@import '../../platformui/Facets/filterbar-facets.scss';
@import './c-collapsible-facets.scss';

@include FilterBarFacets(
  $itemColor: c('sky-base'),
  $itemSelectedColor: c('stone-dark'),
  $selectedItemBorder: 7px solid c('sky-base')
) {
  .c-facets {
    $outline: 2px solid c('stone-dark');

    &__inner {
      border-bottom: 1px solid c('sky-line');
      justify-items: center;
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      gap: rem-calc(8);
      justify-content: center;
      font-family: $secondary-font;

      @include breakpoint(medium) {
        flex-direction: row;
        align-items: center;
      }
    }

    &__item {
      margin: 0;
      border: 1px solid c('sky-base');
      display: flex;
      gap: rem-calc(5);
      border-radius: rem-calc(30);
      padding: rem-calc(4) rem-calc(12);
      background-color: white;

      @include focus-handler();

      &--selected {
        color: white;
        background-color: c('sky-dark');
        border-color: c('sky-dark');
      }

      &:hover {
        color: white;
        background-color: c('sky-dark');
        border-color: c('sky-dark');
      }

      &--disabled {
        pointer-events: none;
        color: #636466;
        background-color: white;
        border-color: c('stone-clear');
      }

      span {
        font-size: rem-calc(14);
      }

      &:focus {
        outline: $outline;
        outline-offset: 2px;
      }
      &:focus-within {
        outline: $outline;
        outline-offset: 2px;
      }
    }
  }
}
