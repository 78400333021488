@mixin TabMenu(
  $fontFamily: inherit,
  $fontColor: black,
  $activeFontColor: gray,
  $borderTrack: white,
  $activeItemBorderTrack: blue,
  $tabBorderColor: gray,
  $tabGap: rem-calc(30)
) {
  .c-tab-menu {
    margin-bottom: 40px;
    padding-bottom: 0;
    border-bottom: $borderTrack;

    ul {
      list-style: none;
      margin-block: 0;
      margin-inline: rem-calc(20);
      padding: 0;
    }

    &__list {
      display: inline-flex;
      padding: 0;
      gap: $tabGap;
    }

    &__button {
      background: none;
      border: 1px solid transparent;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: none;
      color: $fontColor;
      cursor: pointer;
      font-size: 1rem;
      font-family: $fontFamily;
      text-align: center;
      padding: 14px 24px;
      width: 100%;
      @include focus-handler();

      &--active {
        color: $activeFontColor;
        border: 1px solid $tabBorderColor;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        border-bottom: $activeItemBorderTrack;
        margin-bottom: -4px;
      }
    }

    @content;
  }
}
