@import '../../platformui/AlertList/styles.scss';
@import '../../platformui/StatusMessage/styles.scss';

@include AlertList($spaceBetween: 0) {
  .c-alertlist {
    @include StatusMessage($icon: '../../framework/images/alert.svg') {
      .statusmessage {
        @include breakpoint(large) {
          .statusmessage__heading {
            padding-left: 10px;
          }
          .statusmessage__expand-button {
            margin-top: 6px;
          }
        }
        .statusmessage__expand-button {
          padding: 0px;
        }
      }
    }
    h2,
    button {
      @include base-font-family();
    }
  }
}