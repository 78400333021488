@mixin CustomSelect(
  $toggle-bg: gray,
  $toggle-border: gray,
  $toggle-color: black,
  $hover-bg: silver,
  $dropdown-border: whitesmoke,
  $dropdown-color: black,
  $chevron-color: black,
  $active-font-weight: normal,
  $margin: 0 rem-calc(10) 2rem
) {
  .c-custom-select {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: $margin;

    &__toggle {
      cursor: pointer;
      display: inline-block;
      text-align: left;
      position: relative;
      width: 100%;
      padding: rem-calc(13) rem-calc(20);
      padding-right: rem-calc(50);
      background: $toggle-bg;
      border-radius: rem-calc(9);
      border: 1px solid $toggle-border;
      font-weight: $active-font-weight;
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $toggle-color;
    }

    &__dropdown {
      position: absolute;
      z-index: 5;
      top: 100%;
      height: auto;
      width: 100%;
      background-color: white;

      ul,
      li {
        padding: 0;
        margin: 0;
      }
    }

    &__content {
      color: $dropdown-color;
      border: 1px solid $dropdown-border;
      border-radius: rem-calc(9);
      overflow: hidden;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
      li {
        text-overflow: ellipsis;
        cursor: pointer;
        padding-block: rem-calc(16);
        padding-inline: rem-calc(20);

        &:hover {
          background-color: $hover-bg;
          font-weight: $active-font-weight;
        }
      }
    }

    &__item--highlighted {
      background-color: $hover-bg;
      font-weight: $active-font-weight;
    }

    @include Chevron(
      $className: 'c-custom-select__chevron',
      $height: rem-calc(5),
      $width: rem-calc(5),
      $lineLength: 11px
    ) {
      position: absolute;
      top: 2px;
      transform: translateY(-50%);
      right: rem-calc(10);
      background-color: transparent;
      transform: scale(0.8);
      &:before,
      &:after {
        background-color: $chevron-color;
      }
    }
    @content;
  }
}
