@import '../../platformui/Alert/styles.scss';

@include Alert(
  $warningIcon: '../../framework/images/alert.svg',
  $warningBackgroundColor: $white,
  $borderStyle: 1px solid,
  $warningBorderColor: c('stone-clear'),
  $checkIcon: '../../framework/images/alert.svg',
  $infoIcon: '../../framework/images/alert.svg'
) {
  .c-alert {
    align-items: unset;
    padding: 20px 0;
    border-radius: 10px;

    &__inner {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__icon {
      background-size: 23px;
      height: 23px;
      margin: 0;
      width: 23px;

      @include breakpoint(large) {
        margin: 0 12px;
        background-size: 30px;
        height: 30px;
        width: 30px;
      }
    }

    &__icon-container {
      align-items: unset;
      float: left;
      left: 0;
      margin-right: 12px;
      position: relative;
      top: 2px;

      @include breakpoint(medium) {
        top: -5px;
      }

      @include breakpoint(large) {
        align-items: center;
        left: unset;
        top: unset;
        margin: 0;

        .contact-alert & {
          position: static;
        }
      }
    }

    p {
      @include breakpoint(large) {
        font-size: 1.125rem;
      }

      padding: 0;
    }
  }
}
